/* eslint-disable @typescript-eslint/no-explicit-any */

export  function getCurrentFolder(folders: any[],newRoleFolder: any){

  console.log('Test any',folders,newRoleFolder);
    let newValue: any[] = [];
  
    if(newRoleFolder === 'General'){
      newValue = folders;
    }else{   

      newValue = folders.filter((obj)=>
      newRoleFolder.includes(obj.title)
      )

    }

    //validate uid
    const listUid: any = newValue.map(obj => obj.uid);
    
    // Guardar uid en el localStorage
localStorage.setItem("uid", listUid);

 
  return newValue;
}

export async function getUserRole(email: String){

    try {
    //const response = (await fetch('http://localhost:9000/api/v1/roles/list/'+email)).json();
    const response = (await fetch('https://bi.api.metropolitan.jacidi.com/api/v1/roles/list/'+email)).json();
    
    
    return response; // Devuelve los datos obtenidos
  } catch (error) {
    throw error; // Lanza el error para que pueda ser manejado por el llamador
  }
}

export async function getCenterRole(email: String){

  try {
  //const response = (await fetch('http://localhost:9000/api/v1/roles/centers/'+email)).json();
  const response = (await fetch('https://bi.api.metropolitan.jacidi.com/api/v1/roles/centers/'+email)).json();
  
  return response; // Devuelve los datos obtenidos
} catch (error) {
  throw error; // Lanza el error para que pueda ser manejado por el llamador
}
}



export async function getUseFolder(folders: any[]) {
  
  const result = await getCurrentFolder(folders,'');
  return result;
}

export function getDashboardInitCenter(objInit: any){

  let centers: any = localStorage.getItem('centers');
    let arraycenter: any;

    
    if(centers=== 'General'){
      return objInit;
    }else if (centers != null && centers.indexOf(",") === -1){  
       
      return { ...objInit, current:{multi:false,text:centers,value:centers},multi:false};
    }else if (centers != null && centers.length>1 ){
     
      arraycenter = centers.split(',');
      return { ...objInit, current:{multi:true,text:arraycenter,value:arraycenter}};
    }else{
      return objInit;
    }
  


}

export function getDropdown(objInit: any,centers: any,restProps: any){  

  if(restProps.id === 'options-db'){
    if(centers === 'General'){
      return objInit;
    }else{
      return objInit.filter((obj: any)=>
      centers.includes(obj.text)
      );
    }
    
  }else{
    return objInit;
  }
  
}

export function getPickerOptions(currentOptions: any, centers: any){
  if(centers === 'General'){
    return currentOptions;
  }else{
    return currentOptions.filter((obj: any)=>
    centers.includes(obj.text)
    );
  }
}

export function getRenderPicker(objInit: any,centers: any){

  if(objInit.name === 'db'){
    let arrayCurrent = objInit.current.text.filter((center: any)=>
    centers.includes(center)
  );
    let result = {...objInit, current:{
    selected: true, text:arrayCurrent, value: arrayCurrent
    },options: getPickerOptions(objInit.options,centers)}
    
    return result;

  }else {
    return objInit;
  }

}

export function validateFolderDashboard(currentFolder: any, folderRole: any){

if (folderRole === 'General' ||(currentFolder && currentFolder.toLowerCase() ==='general' )){
  return true;
}else if(currentFolder && folderRole.trim().includes(currentFolder.trim())) {
  return true;
} else {
  return false;
}

}


export function getFilterArrayIndex(currentArray: any, condition: any){
  return currentArray.filter((element: any, index: any) => condition.includes(index));
}



export function validateHeaderSearch(headerGroup: any) {
  if (!headerGroup || !headerGroup.headers) {
       return null; 
  }

  const indexReferences: any = [];
  const headers: any = headerGroup.headers;

  // Buscar y verificar si existe 'column-location' en headers.
  const headerLocation: any = headers.find((field: any) => field.id === 'column-location');

  if (headerLocation) {
    const uid: any = localStorage.getItem("uid");

    headerLocation.field.values.buffer.forEach((obj: any, i: any) => {
      if (uid.includes(obj)) {
        indexReferences.push(i);
      }
    });

    headers.forEach((element: any) => {
      // Verificar si 'element.field' y 'element.field.values' no son nulos o indefinidos.
      if (element.field && element.field.values) {
        
        element.field.values.buffer = getFilterArrayIndex(element.field.values.buffer, indexReferences);
        
      }
    });
  }

  return headers;
}

export function validateSearch(update: any) {
  
  let newUpdate: any = {...update};
  const indexReferences: any = [];
  if ("result" in update) {
    const { result } = update;

    // Asegúrate de que result.view y result.view.data existan antes de continuar
    if (result.view && result.view.data) {
      const { data } = result.view;

      const locationField = data.fields.find((field: any) => field.name === 'location');

      if (locationField) {
        const uid: any = localStorage.getItem("uid");
        
        if (uid  ) {
          /*const filteredArray = locationField.values.buffer.filter((value: any) => {
            return uid.includes(value);
          });*/

          locationField.values.buffer.map((obj: any,i: any)=>{
              if(uid.includes(obj)){
                indexReferences.push(i);
              }
          })
          
          newUpdate.result.totalRows = indexReferences.length;
          return ({...newUpdate,result:{...newUpdate.result,view:{...newUpdate.result.view,data:{...newUpdate.result.view.data,fields:newUpdate.result.view.data.fields.map((obj: any) =>{return { ...obj,values:{...obj.values,buffer:getFilterArrayIndex(obj.values.buffer,indexReferences)}}}),length:indexReferences.length},fields:{...newUpdate.result.view.fields,kind:{...newUpdate.result.view.fields.kind,values:{...newUpdate.result.view.fields.kind.values,buffer:getFilterArrayIndex(newUpdate.result.view.fields.kind.values.buffer,indexReferences)}},location:{...newUpdate.result.view.fields.location,values:{...newUpdate.result.view.fields.location.values,buffer:getFilterArrayIndex(newUpdate.result.view.fields.location.values.buffer,indexReferences)}},name:{...newUpdate.result.view.fields.name,values:{...newUpdate.result.view.fields.name.values,buffer:getFilterArrayIndex(newUpdate.result.view.fields.name.values.buffer,indexReferences)}},tags:{...newUpdate.result.view.fields.tags,values:{...newUpdate.result.view.fields.tags.values,buffer:getFilterArrayIndex(newUpdate.result.view.fields.tags.values.buffer,indexReferences)}},uid:{...newUpdate.result.view.fields.uid,values:{...newUpdate.result.view.fields.uid.values,buffer:getFilterArrayIndex(newUpdate.result.view.fields.uid.values.buffer,indexReferences)}},url:{...newUpdate.result.view.fields.url,values:{...newUpdate.result.view.fields.url.values,buffer:getFilterArrayIndex(newUpdate.result.view.fields.url.values.buffer,indexReferences)}}}}},loading:false})
        } else {
          console.log('El valor "uid" no está definido en el localStorage.');
          return ({...newUpdate,result:{...newUpdate.result,view:{...newUpdate.result.view,data:{...newUpdate.result.view.data,fields:newUpdate.result.view.data.fields.map((obj: any) =>{return { ...obj,values:{...obj.values,buffer:[]}}}),length:indexReferences.length},fields:{...newUpdate.result.view.fields,kind:{...newUpdate.result.view.fields.kind,values:{...newUpdate.result.view.fields.kind.values,buffer:[]}},location:{...newUpdate.result.view.fields.location,values:{...newUpdate.result.view.fields.location.values,buffer:[]}},name:{...newUpdate.result.view.fields.name,values:{...newUpdate.result.view.fields.name.values,buffer:[]}},tags:{...newUpdate.result.view.fields.tags,values:{...newUpdate.result.view.fields.tags.values,buffer:[]}},uid:{...newUpdate.result.view.fields.uid,values:{...newUpdate.result.view.fields.uid.values,buffer:[]}},url:{...newUpdate.result.view.fields.url,values:{...newUpdate.result.view.fields.url.values,buffer:[]}}}}},loading:false})
        }
      } else {
        console.log('No se encontró un campo llamado "location" en el objeto data.fields.');
        return newUpdate;
      }
    } else {
      console.log('El objeto "result.view.data" no está definido o es nulo.');
      return newUpdate;
    }
  }
}


