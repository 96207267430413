import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now/w', to: 'now/w', display: 'Esta semana' },
  { from: 'now/M', to: 'now/M', display: 'Este mes' },
  { from: 'now/y', to: 'now/y', display: 'Este año' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Ayer' },
   { from: 'now-7d', to: 'now', display: 'Ultimos 7 Dias' },
  { from: 'now-30d', to: 'now', display: 'Ultimos 30 Dias' },
  { from: 'now-90d', to: 'now', display: 'Ultimos 90 Dias' },
  { from: 'now-6M', to: 'now', display: 'Ultimos 6 Meses' },
  { from: 'now-1y', to: 'now', display: 'Ultimo Año' }
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];
